:root {
  --sherpa-blue-color: #033540;
  --dark-blue-color: #036280;
  --blue-color: #378ba4;
  --sky-blue-color: #a7d1d2;
  --light-color: #e8ede7;
}
body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-style: italic;
}
html {
  /* Create a snapping rule on the html element */
  scroll-snap-type: y mandatory;
}

/* Add this CSS to your stylesheet or CSS module */

.navbar-toggle {
  position: relative;
  width: 30px;
  height: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  border: none;
}

.bar {
  display: block;
  width: 100%;
  height: 2px;
  background-color: white;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.navbar-toggle.active .bar:nth-child(1) {
  transform: rotate(45deg) translateY(13px);
}

.navbar-toggle.active .bar:nth-child(2) {
  opacity: 0;
}

.navbar-toggle.active .bar:nth-child(3) {
  transform: rotate(-45deg) translateY(-13px);
}
.navcol div {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 16px;
  font-style: italic;
}
.navcol div :hover {
  border-bottom: 2px solid white;
  padding-bottom: 4px;
  color: #67b3ca;
}

.play {
  font-family: "Playwrite CU", cursive;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

/* Custom media queries for specific breakpoints */
/* @media (max-width: 767px) and (min-width: 330px) {
  .navbar-toggle {
    display: flex;
  }
} */

@media (min-width: 767px) {
  .navbar-toggle {
    display: none;
  }
}

section {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
/* ========= Colors ========== */
.text-color-sherpa-blue {
  color: var(--sherpa-blue-color);
}
.background-color-sky-blue {
  background-color: var(--sky-blue-color);
}
.background-color-light {
  background-color: var(--light-color);
}
.background-color-sherpa-blue {
  background-color: var(--sherpa-blue-color);
}

.slick-slide > div {
  margin: 0 10px;
}

/* <-------Tools Start--------> */
.tools {
  width: 100%;
  height: 100vh;
  background-color: #238ca3;
}

/*======== Home Start ========== */
.fixed-button {
  position: fixed;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
}
.fixed-button .btn {
  font-size: 24px;
  padding: 20px 15px;
  background: #238ca3;
  color: #fff;
  border-radius: 30px 0px 0px 30px;
  text-decoration: none;
  pointer-events: all;
}
.fixed-button .additional-buttons {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  /* transform: translate(-50%, -50%); Center the container */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fixed-button .additional-buttons .btn {
  position: absolute;
  border-radius: 25px;
  padding: 10px 10px;
}

.fixed-button .additional-buttons .btn.top {
  right: 2px;
  top: -80px; /* Position above the login button */
}

.fixed-button .additional-buttons .btn.right {
  right: 40px;
  top: -20px; /* Position to the right of the login button */
}

.fixed-button .additional-buttons .btn.bottom {
  right: 1px;
  top: 35px; /* Position below the login button */
}

.home-head {
  animation: slide-left 2s;
}
@keyframes slide-left {
  from {
    transform: translateX(-300%);
  }
  to {
    transform: translateX(0);
  }
}
.home-content {
  animation: slide-right 2s;
}
@keyframes slide-right {
  from {
    transform: translateX(300%);
  }
  to {
    transform: translateX(0);
  }
}

/*======== Team Start ========== */
.team {
  padding: 20px 15px;
}
.team-content {
  display: none;
}
.team-content:target {
  display: block;
}
.scrolling-wrapper {
  overflow-x: auto;
}
::-webkit-scrollbar {
  display: none;
}
/* Service Start */
/* .service-card:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
} */

/* ========== FAQ Start ========== */
.question {
  padding: 20px 80px 20px 20px;
  cursor: pointer;
}
.shadow-img {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.bg-gradient {
  background: linear-gradient(to top left, #033540, #63898c, #e0f4f5);
}

/* .bg-animate {
  background-position: 0 0;
  background-repeat: repeat-x;
  animation: animatedBackground 30s linear infinite;
}
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
} */

.gradient-overly-right {
  /* z-index: 1; */
  /* width: 100vw;
  min-width: 100vw; */
  position: relative;
  background-image: url(./Component/assets/img/home/homeimg2.jpg);
  background-position: center;
  background-size: cover;
}
/* .gradient-overly-right:before {
  content: "";
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
   background: linear-gradient(
    90deg,
    #0a0a15 2%,
    rgb(29 30 61 / 50%),
    var(--sherpa-blue-color)
  ) !important; 
} */

/* <-------Bubble Animation Start---------> */
/* .bubbles{
  position:absolute;
  width:100%;
  height: 100%;
  z-index:0;
  overflow:hidden;
  top:0;
  left:0;
}
.bubble{
  position: absolute;
  bottom:-100px;
  width:40px;
  height: 40px;
  background:#fff;
  border-radius:50%;
  animation: rise 10s infinite ease-in;
}
.bubble:nth-child(1){
  width:40px;
  height:40px;
  left:10%;
  animation-duration:8s;
}
.bubble:nth-child(2){
  width:20px;
  height:20px;
  left:20%;
  animation-duration:5s;
  animation-delay:1s;
}
.bubble:nth-child(3){
  width:50px;
  height:50px;
  left:35%;
  animation-duration:7s;
  animation-delay:2s;
}
.bubble:nth-child(4){
  width:80px;
  height:80px;
  left:50%;
  animation-duration:11s;
  animation-delay:0s;
}
.bubble:nth-child(5){
  width:35px;
  height:35px;
  left:55%;
  animation-duration:6s;
  animation-delay:1s;
}
.bubble:nth-child(6){
  width:45px;
  height:45px;
  left:65%;
  animation-duration:8s;
  animation-delay:3s;
}
.bubble:nth-child(7){
  width:90px;
  height:90px;
  left:70%;
  animation-duration:12s;
  animation-delay:2s;
}
.bubble:nth-child(8){
  width:25px;
  height:25px;
  left:80%;
  animation-duration:6s;
  animation-delay:2s;
}
.bubble:nth-child(9){
  width:15px;
  height:15px;
  left:70%;
  animation-duration:5s;
  animation-delay:1s;
}
.bubble:nth-child(10){
  width:90px;
  height:90px;
  left:25%;
  animation-duration:10s;
  animation-delay:4s;
}
@keyframes rise{
  0%{
    bottom:-100px;
    transform:translateX(0);
  }
  50%{
    transform:translate(100px);
  }
  100%{
    bottom:1080px;
    transform:translateX(-200px);
  }
} */
/* <----Bubble Animation End-------> */

.header {
  position: fixed;
  /* padding: 15px; */
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: 0.3s ease-in;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.header-bg {
  background-image: linear-gradient(to top, #a7d1d2 0%, #0c6073 100%);
  transform: rotateY(360deg);
  transition: 2s;
}

@keyframes progressAnimation {
  0% {
    width: 5%;
  }
  100% {
    width: 83%;
  }
}
@keyframes barAnimation {
  0% {
    width: 5%;
  }
  100% {
    width: 60%;
  }
}
@keyframes lastAnimation {
  0% {
    width: 5%;
  }
  100% {
    width: 75%;
  }
}

/* ======== Button ========== */
.theme-btn a {
  position: relative;
  display: inline-block;
  font-family: "cerebri_sanssemibold";
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px;
  transition: 0.3s;
  border-radius: 5px;
  vertical-align: middle;
  border: 2px solid #a7d1d2;
}
.theme-btn a::before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  background: #033540;
  -webkit-transition: all 0.3s ease;
  transition: all 0.4s ease;
}
.theme-button {
  color: #033540;
  z-index: 9;
  position: relative;
  transition: 0.3s;
}
.theme-btn a:hover {
  border-color: #033540;
}
.theme-btn a:hover::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.theme-btn a:hover .theme-button {
  color: #a7d1d2;
}

.service-btn:hover .btn--jump {
  animation: jump 1s ease-out infinite;
}
.btn--jump:hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  animation: jumpEffect 1s 0.65s ease-out infinite;
}
.btn--jump:hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  animation: jumpEffect 1s 0.5s ease-out infinite;
}
@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(-25px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes jumpEffect {
  0%,
  100% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1.05);
  }
}
.slick-slider {
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  padding-bottom: 25px;
}
.skill-bg {
  background: url(./Component/assets/img/apply-bg.jpg) fixed center center;
}
.counter-box {
  background-color: rgba(250, 250, 250, 0.3);
}

/* <-----------Service Css---------------> */

.service {
  width: 100%;
  padding: 20px 0px;
  background-image: repeating-radial-gradient(
    circle at left bottom,
    #a7d1d2,
    #d9f6f7 50%
  );
}
/* .service-card {
  background: #2b8da3;
  border-radius: 20px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -5px;
  animation: slide-in-left 5s;
}
.service-card:hover {
  transform: scale(1.07);
}
@keyframes slide-in-left {
  from {
    transform: translateX(-300%);
  }
  to {
    transform: translateX(0);
  }
}  */

/* styles.css or any CSS-in-JS solution */
.service-card {
  background: #2b8da3;
  border-radius: 20px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -5px;
  opacity: 0; /* Start invisible */
  transform: translateX(-300%); /* Start slightly off-screen */
  transition: opacity 0.6s ease-out, transform 4s ease-out;
}

.service-card.show {
  opacity: 1;
  transform: translateY(0);
}

.service-card:hover {
  transform: scale(1.09);
}

.service-textBox {
  animation: anim 3s infinite;
}

.service-card:hover .service-textBox {
  opacity: 1;
}

.service-card:hover > .service-img {
  height: 65%;
  filter: blur(7px);
  animation: anim 3s infinite;
}

@keyframes anim {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

/* <-------Service Css End -------> */

.client {
  /* height: 100vh; */
  padding: 10px 0px;
  background-image: repeating-radial-gradient(
    circle at left bottom,
    #a7d1d2,
    #d9f6f7 50%
  );
}
.icon-style {
  color: #113435;
}
.icon-style :hover {
  /* background-color: #033540; */
  color: #a7d1d2;
  background-color: #144550;
  box-shadow: 0 0 15px #033540;
  text-shadow: 0 0 15px #033540;
  padding: 5px;
  border-radius: 50px;
}
